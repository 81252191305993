<template>
  <div class="shop-goods-list-page">
    <en-table-layout :tableData="pageData.data" :loading="loading" @selection-change="handleSelectionChange"
      @sort-change="handleSortChange" ref="tableWrap">
      <div slot="header" style="padding:15px 15px 0 15px;">
        <el-form inline class="row no-gutters align-items-center">
          <el-form-item label="状态" class="col-auto" size="small">
            <el-select v-model="params.comboStatus" placeholder="请选择" clearable style="width:80px;" @change="search"
              size="small">
              <el-option label="全部" value=""></el-option>
              <el-option label="禁用" :value="0"></el-option>
              <el-option label="启用" :value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="width:25%">
            <el-input size="small" v-model="params.lowEnterPrice" placeholder="￥分销总价最小" style="width:46%;" />
            <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
            <el-input size="small" v-model="params.highEnterPrice" placeholder="￥分销总价最大" style="width:46%;" />
          </el-form-item>
          <el-form-item style="width:25%">
            <el-input size="small" v-model="params.lowMktPrice" placeholder="￥市场总价最小" style="width:46%;" />
            <span style="margin: 0 6px;letter-spacing: -2px;color:#999999;">---</span>
            <el-input size="small" v-model="params.highMktPrice" placeholder="￥市场总价最大" style="width:46%;" />
          </el-form-item>
          <div class="col"></div>
          <el-form-item class="col-auto">
            <el-input style="width: 188px;" placeholder="请输入礼包/商品名称" v-model="params.keyword" clearable size="small">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="是否异常" label-width="80px" style="margin-bottom: 8px;">
            <el-select v-model="params.normalStatus" style="width: 90px">
              <el-option value label="全部"></el-option>
              <el-option :value="0" label="异常"></el-option>
              <el-option :value="1" label="正常"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="礼包分类" label-width="100px">
            <el-cascader clearable style="width: 215px;" v-model="categoryParams" :options="JDCategory.children"
              :props="JDCategory.props" placeholder="请选择礼包分类" class="cas-select" />
          </el-form-item>
          <el-form-item class="col-auto ml-2">
            <el-button @click="search" type="primary" size="small">
              搜索
            </el-button>
          </el-form-item>
        </el-form>

      </div>
      <template slot="toolbar">
        <div class="col-auto">
          <!--商品状态 上架 下架-->
          <el-button @click="handleAddCombo" type="primary" size="small">
            新建礼包
          </el-button>

          <el-button size="small" @click="handlePick2Card" type="primary">
            挑选至卡券
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" :key="'selection'" />

        <el-table-column :key="'code'" label="编号" width="50" fixed="left">
          <template slot-scope="scope">
            {{ code(scope.$index, params) }}
          </template>
        </el-table-column>

        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper" :key="'thumbnail'">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px">
              <!-- <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" /> -->
              <div slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="礼包名称" width="180" :show-overflow-tooltip="true" fixed="left" :key="'gift_name'">
          <template slot-scope="scope">
            {{ scope.row.gift_name }}
          </template>
        </el-table-column>

        <el-table-column label="商品数量" :key="'goods_num'">
          <template slot-scope="scope">
            <span class="num" @click="handleComboGoodsNum(scope.row)">{{
              scope.row.goods_num
            }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="parentLogin === 1" label="会员总价" width="110" prop="memberPrice" sortable="custom"
          :key="'sum_price'">
          <template slot-scope="scope">
            {{ scope.row.sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="分销总价" width="110" :key="'enterprisePrice'" prop="enterprisePrice" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.enterprise_sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="市场总价" width="110" prop="marketPrice" sortable="custom" :key="'market_sum_price'">
          <template slot-scope="scope">
            {{ scope.row.market_sum_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="销售总价" width="110" :key="'shopPrice'" prop="shopPrice" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.shop_sum_price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="套餐价" width="110" :key="'comboPrice'" prop="comboPrice" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.combo_price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="parentLogin === 1" label="利润率" width="100" prop="profitMargin" sortable="custom"
          :key="'shop_profit'">
          <template slot-scope="scope">
            {{ scope.row.shop_profit.toFixed(2) || 0 }}%
          </template>
        </el-table-column>

        <el-table-column label="更新时间" width="160" prop="time" :key="'time'">
          <template slot-scope="scope">{{ scope.row.update_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>
        <el-table-column label="礼包分类" key="category_name" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.category_name }}
          </template>
        </el-table-column>
        <el-table-column label="礼包状态" width="80" :key="'status'">
          <template slot-scope="scope">
            <span>{{ scope.row.status | comboStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" :render-header="renderHeader" fixed="right" :key="Math.random()">
          <template slot-scope="scope">
            <div class="operate" style="white-space: nowrap; display: inline-block">
              <el-tooltip v-if="!scope.row.can_delete" class="item" effect="dark" content="绑卡记录" placement="top-start">
                <el-button size="mini" type="text" @click="handleComboBinding(scope.row)"><img :src="changelog_icon"
                    alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" v-if="scope.row.can_delete" effect="dark" content="删除" placement="top-start">
                <!-- 礼包未绑卡的时候，可以删除（状态默认为启用状态）；一旦绑卡后，删除按钮去掉。 -->
                <el-button size="mini" type="text" @click="handleDeleteCombo(scope.row)"><img :src="delete_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" effect="dark" content="编辑" placement="top-start">
                <el-button size="mini" type="text" @click="handleEditCombo(scope.row)"><img :src="edit_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" v-if="scope.row.status === 1" effect="dark" content="禁用" placement="top-start">
                <el-button size="mini" type="text" @click="handleNoUseCombo(scope.row)">
                  <img :src="disabled_icon" alt="">
                </el-button>
              </el-tooltip>

              <el-tooltip class="item" v-if="scope.row.status === 0" effect="dark" content="启用" placement="top-start">
                <el-button size="mini" type="text" @click="handleUseCombo(scope.row)">
                  <img :src="enable_icon" alt="">
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="pageData.data_total" />
    </en-table-layout>

    <!-- 挑选至卡券 -->
    <el-dialog-x-dialog title="操作" :visible.sync="pick2CardFormVisible" width="30%">
      <el-form :model="pick2CardForm" style="padding: 10px 20px;">
        <el-form-item label="请选择">
          <el-select v-model="pick2CardForm.cardId" placeholder="套餐卡券选择" clearable filterable>
            <el-option v-for="card in cards" :key="card.id" :label="card.card_name" :value="card.id"></el-option>
          </el-select>

        </el-form-item>
        <el-form-item style="text-align:right">
          <el-button @click="pick2CardForm.cardId = ''; pick2CardFormVisible = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="handlePick2CardConfirm">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>

    <!--绑卡记录-->
    <el-dialog-x-dialog title="绑卡记录" :visible.sync="comboBindingRecordsVisible">

      <en-table-layout :table-data="comboBindingTableData.data" :loading="comboRecordsLoading">
        <template slot="toolbar">
          <el-form-item>
            <el-input v-model="comboBindingRecordsParams.card_name" placeholder="请输入卡券名称" clearable />
          </el-form-item>
          <el-form-item>
            <el-button @click="searchRecords" size="small" type="primary">
              搜索
            </el-button>
          </el-form-item>
        </template>
        <template slot="table-columns">
          <el-table-column :key="'code'" label="编号" width="80" fixed="left">
            <template slot-scope="scope">
              {{ code(scope.$index, comboBindingRecordsParams) }}
            </template>
          </el-table-column>
          <el-table-column label="所绑卡券名称" width="200" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.card_name }}
            </template>
          </el-table-column>
          <el-table-column label="绑卡时间">
            <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
        </template>
        <el-pagination slot="pagination" @size-change="handlePageSizeChangeRecord"
          @current-change="handlePageCurrentChangeRecord" :current-page="comboBindingTableData.page_no"
          :page-size="comboBindingTableData.page_size" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
          :total="comboBindingTableData.data_total" />
      </en-table-layout>
    </el-dialog-x-dialog>

    <!--查看商品数量，商品展示-->
    <el-dialog-x-dialog title="商品展示" :visible.sync="comboGoodsNumVisible">

      <en-table-layout :table-data="comboGoodsNumTableData.data">
        <template slot="table-columns">
          <el-table-column label="商品名称" :show-overflow-tooltip="true" fixed="left">
            <template slot-scope="scope">
              {{ scope.row.goods_name }}
            </template>
          </el-table-column>
          <el-table-column label="前端名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.goods_alias }}
            </template>
          </el-table-column>
          <el-table-column label="商品来源" width="100">
            <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
            <template slot-scope="{row}">
              <template v-if="row.goods_source === 0 && row.self_operated === 0">平台商品</template>
              <template v-if="row.goods_source === 0 && row.self_operated === 1">自有商品</template>
              <template v-if="row.goods_source === 1 && row.self_operated === 0">京东商品</template>
              <!-- <template v-if="row.goods_source === 0">{{ row.self_operated === 1 ? '自有商品' : '平台商品' }}</template> -->
              <!-- <template v-if="row.goods_source === 2">苏宁商品</template> -->
            </template>
          </el-table-column>
          <el-table-column label="数量" width="90">
            <template slot-scope="scope">
              {{ scope.row.num }}
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
    </el-dialog-x-dialog>

  </div>
</template>

<script>
import * as API_goods from "@/api/goods";
import * as API_Combo from "@/api/combo-card-shop/combo";
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import statusErr from "@/assets/goods/statusErr.png";
import changelog_icon from "@/assets/button_icon/changelog.png";//绑卡记录
import edit_icon from "@/assets/button_icon/edit.png";//编辑
import enable_icon from "@/assets/button_icon/enable.png";//启用
import disabled_icon from "@/assets/button_icon/disabled.png";//禁用
import delete_icon from "@/assets/button_icon/delete.png";//删除
import { $giftsCategory } from '@/pages/goods/services/gifts-category'
export default {
  name: "comboListComboCard",
  components: {
    EnTableLayout,
  },
  data () {
    return {
      categoryParams: '',
      JDCategory: $giftsCategory.create(),
      changelog_icon,
      edit_icon,
      enable_icon,
      disabled_icon,
      delete_icon,
      /** 列表loading状态 */
      loading: false,
      /** 绑卡记录列表loading状态 */
      comboRecordsLoading: false,
      idsList: [],

      showDialog: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        highEnterPrice: "",
        lowEnterPrice: "",
        highMktPrice: "",
        lowMktPrice: "",
        keyword: "",
        sort: "def_desc",
        comboStatus: "",
        normalStatus: void 0
      },

      /** 列表分页数据 */
      pageData: {},

      /** 绑卡记录字段*/
      comboBindingRecordsVisible: true,
      comboBindingTableData: {},
      comboBindingRecordsParams: {
        page_no: 1,
        page_size: 20,
        card_name: "",
      },
      /** 查看商品数量，商品展示字段*/
      comboGoodsNumVisible: true,
      comboGoodsNumTableData: {},
      /**挑选卡券字段 */
      pick2CardFormVisible: false,
      pick2CardForm: {
        cardId: "",
      },
      cards: [],
      /**是否是企业跳转过来 */
      parentLogin: 0,
    };
  },
  filters: {
    /** 礼包状态格式化 */
    comboStatus (status) {
      switch (status) {
        case 0:
          return "禁用";
        case 1:
          return "启用";
      }
    },
  },
  created () {
    API_goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
  },
  activated () {
    this.GET_ComboList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    this.GET_ComboList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  methods: {
    // 表头部重新渲染
    renderHeader (h, { column, $index }) {
      setTimeout(() => {
        // 获取操作按钮组的元素
        const opts = document.getElementsByClassName('operate');
        let widthArr = [];
        // 取操作组的最大宽度
        Array.prototype.forEach.call(opts, function (item) {
          if (item.innerText || item.children) {
            widthArr.push(item.offsetWidth);
          }
        });
        // 重新设置列标题及宽度属性
        if (widthArr.length > 0) {
          column.width = Math.max(...widthArr) + 24;
        } else {
          column.width = 80;
        }
      }, 1)
      return h('span', column.label);
    },
    getRowTagImage (row) {
      if (row.normal_status === 0) return statusErr
    },
    /**
     * 查看商品
     */
    handleComboGoodsNum (row) {
      this.comboGoodsNumVisible = true;
      this.comboGoodsNumTableData.data = [];
      row.goods_volist.forEach((item) => {
        let {
          goods_alias,
          goods_name,
          num,
          shop_goods_vo: {
            goods_do: { goods_source, self_operated },
          },
        } = item;
        this.comboGoodsNumTableData.data.push({
          goods_name,
          goods_alias,
          goods_source,
          self_operated,
          num,
        });
      });
    },
    /**
     * 绑卡记录
     */
    handleComboBinding (row) {
      this.comboBindingRecordsVisible = true;
      this.comboBindingRecordsParams.combo_id = row.id;
      this.GET_ComboRecordsList();
    },
    /**
     * 添加套餐
     */
    handleAddCombo () {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "comboEditComboCard",
      });
    },
    /**
     * 挑选至卡券
     */
    handlePick2Card () {
      if (this.idsList.length) {
        API_Combo.getCanPickedCardList({ ids: this.idsList }).then((res) => {
          this.cards = res;
          this.pick2CardFormVisible = true;
        });
      } else {
        this.$message.error("请选择挑选到卡券的礼包");
      }
    },
    /**
     * 挑选至卡券确认按钮
     */
    handlePick2CardConfirm () {
      if (this.pick2CardForm.cardId) {
        API_Combo.combo2Card(this.pick2CardForm.cardId, this.idsList).then(
          (res) => {
            // console.log(res);
            if (res === "卡券不存在或未启用") {
              this.$message.error(res);
            } else {
              this.$message.success("挑选成功");
              this.pick2CardFormVisible = false;
              this.pick2CardForm.cardId = "";
              this.GET_ComboList();
            }
          }
        );
      } else {
        this.$message.error("请选择挑选到的卡券");
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_ComboList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_ComboList();
    },

    /** 绑卡记录分页大小发生改变 */
    handlePageSizeChangeRecord (size) {
      this.params.page_size = size;
      this.GET_ComboRecordsList();
    },

    /** 绑卡记录分页页数发生改变 */
    handlePageCurrentChangeRecord (page) {
      this.params.page_no = page;
      this.GET_ComboRecordsList();
    },

    search () {
      this.params.page_no = 1;
      this.GET_ComboList();
    },
    /**查询绑卡记录 */
    searchRecords () {
      this.comboBindingRecordsParams.page_no = 1;
      this.GET_ComboRecordsList();
    },

    /** 禁用*/
    handleNoUseCombo (row) {
      this.$confirm(
        "确定要禁用这个套餐礼包吗？禁用后前台用户将无法进行兑换。",
        "提示",
        {
          type: "warning",
        }
      ).then(() => {
        API_Combo.changeStatus(row.id, 0).then(() => {
          this.$message.success("禁用成功");
          this.GET_ComboList();
        });
      });
    },

    /** 启用*/
    handleUseCombo (row) {
      this.$confirm("确定要启用该套餐礼包吗？", "提示", {
        type: "warning",
      }).then(() => {
        API_Combo.changeStatus(row.id, 1).then((res) => {
          if (res !== "success") {
            this.$message.error(res);
          } else {
            this.$message.success("启用成功");
            this.GET_ComboList();
          }
        });
      });
    },

    GET_ComboList () {
      this.loading = true;
      // this.params.shopId = this.$store.getters.shopInfo.shop_id;
      API_Combo.getComboList(this.params).then((response) => {
        if (response.data.length === 0 && response.page_no > 1) {
          this.params.page_no--;
          return this.GET_ComboList();
        }

        this.loading = false;
        this.pageData = response;
        this.$nextTick(() => {
          this.$refs.tableWrap.$refs.table.doLayout();
        });
      });
    },

    GET_ComboRecordsList () {
      this.comboRecordsLoading = true;
      API_Combo.getRecordList(this.comboBindingRecordsParams)
        .then((response) => {
          if (response.data.length === 0 && response.page_no > 1) {
            this.comboBindingRecordsParams.page_no--;
            return this.GET_ComboRecordsList();
          }

          this.comboRecordsLoading = false;
          this.comboBindingTableData = response;
        })
        .finally(() => {
          this.comboRecordsLoading = false;
        });
    },

    /** 编辑礼包*/
    handleEditCombo (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "comboEditComboCard",
        params: {
          id: row.id,
        },
      });
    },

    /** 删除商品 */
    handleDeleteCombo (row) {
      this.$confirm("确定要删除该礼包吗？删除后无法还原该礼包。", "提示", {
        type: "warning",
      }).then(() => {
        API_Combo.delCombo(row.id).then(() => {
          this.GET_ComboList();
          this.$message.success("删除礼包成功！");
        });
      });
    },

    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });

      this.idsList = ids;
    },
    handleSortChange ({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.GET_ComboList();
    },
  },
};
</script>

<style lang="scss" scoped>
.num {
  cursor: pointer;
  color: #2155d5;
  text-decoration: underline;
}

.num:hover {
  text-decoration: underline;
}
</style>
